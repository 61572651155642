<template>
        <div class="details-read">
      <el-row type="flex" justify="space-between" class="title-box">
        <el-col :span="3"
          ><div class="read-title"><span></span>{{title}} </div></el-col
        >
        <el-col :span="3"
          ><span><i title="返回上一级" class="el-icon-back" v-if="viewer.depth > 0" @click="back"></i></span>
          <div v-if="show&&addShow" class="add" @click="addShareFile">
            <span><i class="el-icon-plus"></i></span> 添加
          </div></el-col
        >
      </el-row>
      <div class="read-bottom-box">
        <div :class="{'read-context':true,'read-contexts':nowrap}">
          <el-table size="small" :data="viewer.showFiles" :show-header="false" class="share-edit-table">
            <el-table-column label="标题" width="450">
              <template slot-scope="scope">
                <el-image :src="scope.row | thumbIcon"></el-image>
                <span
                  class="title-link"
                  slot="reference"
                  @click="render(scope.row)"
                  >{{scope.row.name}}</span
                >
              </template>
            </el-table-column>
            <el-table-column label="时间" align="right">
              <template slot-scope="scope">
                <div class="right" v-if="show" @click="delect(scope.row)"><i class="el-icon-delete-solid"></i></div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    <AddShareFile :shareInfo="shareInfo" @addFile='addFile' ref="addShareFile" />

    </div>
</template>
<script>
import { requestAPI, shareAPI, qrReadAPI, documentAPI} from 'liankong-ui-api' 
import AddShareFile from "@/views/share/AddShareFile.vue"

export default {
    props: {
      show:{
        type: Boolean,
        default:false
      },
      addShow:{
        type: Boolean,
        default:true
      },
      shareInfo: {
        type: Object,
        default: {}
      },
      nowrap:{
          type:Boolean,
          default:false
      },
      title:{
          type:String,
          default:'文件资源'
      }
  },
  data() {
      return {
        verker:'shareDocList',
      }
  },
  components:{AddShareFile},
  computed: {
    viewer() {
      return this.$store.state.doc.viewer[this.verker] || {}
    }
  },
  methods:{
    // 添加共享目标
    addShareFile(){
      this.$refs['addShareFile'].visibles = true
    },
    addFile(state=false,resource){
        this.$emit('addFiles',{state,resource})
    },
    back(){
      this.$store.dispatch('doc/backFolder', {
        vkey: this.verker
      })
    },
    // 跳转ofd页面
    render(docInfo){
       docInfo.documentId ? docInfo.documentId = docInfo.documentId : docInfo.documentId = docInfo.id
      if(docInfo.type==0){
        this.$store.dispatch("doc/loadChildrenByShare", {
          vkey: this.verker,
          folder: docInfo
        })
        return
      }
      this.$ebus.$emit('publicReader',{
          shareId:this.shareInfo.id, 
          id:docInfo.documentId || docInfo.id,
          documentId: docInfo.documentId
      })
     
    },
    // 删除共享文件
    delect(val){
      let resource = val
      if(this.shareInfo.resources.length==1){
        this.$message.error('至少有一个文件哦');
      }else{
        this.$store.dispatch("auth/removeShareResource", {
          shareId: this.shareInfo.id,
          resource
        }).then(res=>{
          this.addFile(true,resource)
        })
      }
     
    },
  }
}
</script>
<style lang="less" scoped>
@import "../styles/index";
  .details-read {
    width: 100%;
    margin-top: 32px;
    .title-box {
      border-bottom: 1px dashed #e7eef4;
      padding-bottom: 10px;
    }
    .read-title {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      span {
        display: inline-block;
        height: 20px;
        width: 5px;
        background: @Brand;
        border-radius: 50px;
        margin-right: 10px;
      }
    }
    .add {
      font-size: @h3;
      cursor: pointer;
      display: inline-block;
      margin-left: 10px;
      span {
        border-radius: 50px;
        background: #59b880;
        padding: 2px;
        i {
          color: #fff;
        }
      }
    }
    .read-bottom-box {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
    }
    .read-context {
      width: 100%;
      .el-table {
       /deep/ .el-table__body-wrapper {
         .el-table__body {
          colgroup{
            display:flex;
          }
          tbody {
            display: flex;
            flex-wrap: wrap;
            .el-table__row {
              width: 50%;
              td {
                border:0;
                .cell {
                    display: flex;
                    align-items: center;
                    .el-image {
                      width: 24px;
                      height: 24px;
                    }
                }
              }
            }
          }
        }
       }
       &::before {
        height: 0px;
       }
      }
      .title-link {
        white-space: nowrap;
        position: relative;
        font-size: @h3;
        cursor: pointer;
        margin-left: 10px;
      }
    //   .title-link:after {
    //     content: "";
    //     position: absolute;
    //     display: inline-block;
    //     height: 5px;
    //     width: 5px;
    //     left: -10px;
    //     top: 5px;
    //     background: #d2d2d2;
    //   }
    }
    .read-contexts {
      .el-table {
       /deep/ .el-table__body-wrapper {
         .el-table__body {
          colgroup{
            display:flex;
          }
          tbody {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            .el-table__row {
              width: 50%;
              td {
                border:0;
                .cell {
                    display: flex;
                    align-items: center;
                }
              }
            }
          }
        }
       }
      }
    }
    /deep/ .el-form-item {
     margin-bottom:5px;
    }
  }
</style>


