<template>
    <div class="details-read">
      <el-row type="flex" justify="space-between" class="title-box">
        <el-col :span="1.5"
          ><div class="read-title"><span></span> 共享目标</div></el-col
        >
        <el-col :span="3">
            <div v-if="show" class="add" @click="addPeople">
            <span><i class="el-icon-plus"></i></span> 添加
          </div>
        </el-col>
      </el-row>

      <el-row class="target-context" :gutter="96" justify="start">
        <el-col :span="6" v-for="(item,i) in shareInfo.targets" :key="i">
          <el-row
            class="target-item"
            type="flex"
            justify="space-around"
            align="middle"
          >
            <el-col class="center">
              <div>{{item.attrs.name || item.value}}</div>
            </el-col>
            <el-col v-if="show" class="right"><i class="el-icon-delete-solid" @click="onTargetRemoved(item)"></i></el-col>
          </el-row>
        </el-col>
      </el-row>
      <AddSharePeople ref="addSharePeople" :users='targets' @selected="onTargetAdd" @removed="onTargetRemoved" />
    </div>
</template>
<script>
import AddSharePeople from '@/views/share/AddSharePeople.vue'
export default {
    props: {
      show:{
        type: Boolean,
        default:false
      },
      shareInfo: {
        type: Object,
        default: {}
      },
  },
  components:{
    AddSharePeople
  },
  computed:{
    targets(){
      return this.shareInfo.targets ? this.shareInfo.targets.reduce((map, target) => {
        const key = target.value
        map[key] = target
        return map
      }, {}) : {}
    },
  },
  methods:{
    addPeople(){
      this.$refs['addSharePeople'].visibles = true
    },
    //移除目标
    onTargetRemoved(target){
      this.loadingShow('回收中')
      this.$store.dispatch('auth/removeShareTarget', {
        shareId: this.shareInfo.id,
        target
      }).then(state =>{
       this.loadingHide()
        this.$message.success("授权已收回")
      }).catch(err => {
          console.log(err)
          this.$msg.toastError("收回失败!")
        })
    },
    // 添加目标
    onTargetAdd(target){
      this.loadingShow('授权中')
      this.$store.dispatch('auth/addShareTarget', {
        shareId: this.shareInfo.id,
        target
      }).then(state =>{
         this.loadingHide()
        this.$message.success("授权已完成")
      }).catch(err => {
          console.log(err)
          this.$msg.toastError("授权失败!")
        })
    },
  }
}
</script>
<style lang="less" scoped>
@import "../styles/index";
  .details-read {
    width: 100%;
    margin-top: 32px;
    .title-box {
      border-bottom: 1px dashed #e7eef4;
      padding-bottom: 10px;
    }
    .read-title {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      span {
        display: inline-block;
        height: 20px;
        width: 5px;
        background: @Brand;
        border-radius: 50px;
        margin-right: 10px;
      }
    }
    .add {
      font-size: @h3;
      cursor: pointer;
      display: inline-block;
      margin-left: 10px;
      span {
        border-radius: 50px;
        background: #59b880;
        padding: 2px;
        i {
          color: #fff;
        }
      }
    }
    .read-bottom-box {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
    }
    .read-context {
      width: 100%;
      .el-table {
       /deep/ .el-table__body-wrapper {
         .el-table__body {
          colgroup{
            display:flex;
          }
          tbody {
            display: flex;
            flex-wrap: wrap;
            .el-table__row {
              width: 50%;
              td {
                border:0;
              }
            }
          }
        }
       }
      }
      .title-link {
        white-space: nowrap;
        position: relative;
        font-size: @h3;
        cursor: pointer;
      }
    }
    /deep/ .el-form-item {
     margin-bottom:5px;
    }
  .target-context {
    margin-left: -40px !important;
    .target-item {
      margin-top: 10px;
      .left {
        height: 40px;
        line-height: 40px;
        width: 40px;
        text-align: center;
        border-radius: 5px;
        font-size: 20px;
        color: #fff;
        font-weight: 400;
        margin-right: 22px;
      }
      .center {
        margin-right: 50px;
        div:nth-child(1) {
          font-size: @h3;
        }
        div:nth-child(2) {
          font-size: @h6;
          color: #b6b6b6;
        }
      }
    }
  }
  }

</style>
